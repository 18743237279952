@keyframes loadingBar {
  100% {
    width: 100%;
  }
  0% {
    width: 0;
  }
}

div.panel {
  max-width: 90%;
  margin: auto;
  margin-top: calc(5% - 0px);
}
details {
  background: #fff;
  font-size: 16px;
  border-bottom: 1px solid #e0e0e0;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.24);
  display: flex;
}

details div.content {
  padding: 0px 24px 24px 24px;
}

details[open] summary:after {
  color: #9e9e9e;
  content: "expand_less";
  font-family: "Material Icons";
}
details[open] {
  margin-top: 16px;
  margin-bottom: 16px;
  border: none;
  border-radius: 2px;
}
details[open]:first-child {
  margin-top: 0;
  margin-bottom: 16px;
  border: none;
}
details[open]:last-child {
  margin-top: 16px;
  margin-bottom: 0px;
  border: none;
}

summary {
  outline: none;
  cursor: pointer;
  padding: 16px 24px;
  color: #212121;
  position: relative;
  font-size: 15px;
}
summary:hover {
  background: #eeeeee;
}

details[open] summary:hover {
  background: none;
}
summary ul {
  padding-left: 0;
  list-style: none;
  display: -webkit-flex;
  display: flex;
  align-items: center;
}
summary ul li {
  flex: 1 100%;
  flex-flow: row wrap;
}
summary ul li span {
  display: block;
  font-size: 12px;
  margin-top: 6px;
  opacity: 0.75;
}
summary::-webkit-details-marker {
  display: none;
}
/* summary::after {
  content: "expand_more";
  font-family: "Material Icons";
  color: #9e9e9e;
  font-size: 1.5em;
  padding: 0;
  text-align: center;
  margin-top: 0;
  position: absolute;
  top: calc(50% - 12px);
  bottom: 0;
  right: 0;
  width: 6%;
} */

.titleValue {
  color: #757575;
  /* font-family: Proxima Nova; */
}
time {
  color: #757575;
  opacity: 0.75;
}
.list-unstyled {
  margin-top: 15px;
  padding-left: 0;
  list-style: none;
}
.list-unstyled li {
  margin-bottom: 6px;
}

.loading-bar {
  border: 1px dashed rgba(30, 30, 30, 0.4);
}

.loading-bar #bar {
  width: 100%;
  -webkit-transition: all 30000ms;
  transition: all 30000ms;
  background-color: lightblue;
  height: 25px;
  animation-name: loadingBar;
  animation-duration: 620s;
}

.loading-bar #bar.start {
  animation-duration: 500ms;
}

.hidden {
  display: none;
}
