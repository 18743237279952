.metaverseSpan {
  background-color: #17bcde;
  width: 100%;
  color: #ffffff;
  border-radius: 30px 0px 30px 0px;
  font-size: 35px;
  padding: 3px;
  font-weight: 600;
  /* animation: upToDown 2s; */
}

.metaverseSpan:hover {
  color: #ffffff;
  background-color: #17bcde;
}

.metaverseIcons {
  width: 70px;
}

.metaverseIconText {
  font-size: 18px;
  font-weight: 500;
}
.mettext {
  padding-top: 20px;
}

/* .firstFrame {
  animation: moveToRight 2s;
  animation-delay: 0.5s;
  animation-fill-mode: backwards;
}

.secondFrame {
  animation: moveToRight 2s;
}

.thirdFrame {
  animation: moveToLeft 2s;
}

.fourthFrame {
  animation: moveToLeft 2s;
  animation-delay: 0.5s;
  animation-fill-mode: backwards;
} */

@keyframes upToDown {
  0% {
    transform: translateY(-50px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes moveToLeft {
  0% {
    transform: translateX(80px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes moveToRight {
  0% {
    transform: translateX(-80px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
