h1 {
  font-size: 350%;
}

.intro-logo {
  margin: 15% auto 0 auto;
  height: 50%;
  width: 50%;
}

.intro-title {
  margin: 7% auto 0 auto;
  /* font-family: Arial Rounded MT; */
}

.btn-intro {
  border: 2px solid #00bcdf;
  margin: 7% auto 0 auto;
  width: 250px;
  height: 60px;
  color: #00bcdf;
  /* font-family: ComeniusAntiqua; */
  font-size: 27px;
}

.btn-intro:hover {
  background-color: #00bcdf;
  color: #ffffff;
}

.intro-carousel {
  border-radius: 15px;
  background-color: #00bcdf;
  color: white;
  width: 83%;
  height: 100%;
  margin: 0 auto 0 auto;
}

.intro-text {
  margin: 7% 5% 7% 5%;
  text-align: center;
  /* font-family: Arial Rounded MT; */
}

.intro-bottom-box-icon {
  width: 25px;
  height: 25px;
  margin: 15% auto 8% auto;
}

.intro-bottom-box-title {
  margin: 0 auto 0 auto;
  text-align: center;
   justify-content: center;
}

.intro-bottom-box {
  width: 85%;
  margin: 0 auto 0 auto;
}

.intro-bottom-box-text {
  margin-top: 2%;
  text-align: justify;
  font-size: 14px;
  text-align-last: center;
}

.flow-control {
  overflow-x: none;
}

@media only screen and (max-width: 770px) {
  .intro-logo {
    margin: 0 auto 0 auto;
    height: 50%;
    width: 50%;
  }
}
