.workSpan {
  background-color: #17bcde;
  width: 100%;
  color: #ffffff;
  border-radius: 30px 0px 30px 0px;
  font-size: 35px;
  padding: 3px;
  font-weight: 600;
  /* animation: animateWorSpan 2s; */
}

.workSpan:hover {
  color: #ffffff;
  background-color: #17bcde;
}

.workIcon {
  width: 50px;
}

.workHeading {
  font-weight: 700;
  font-size: 20px;
  color: #0d0a0e;
}

.workDesc {
  font-size: 15px;
  color: #1c1e1c;

}

/* .workContentAnimation {
  animation: workContentAnimateDownToUp 2s;
} */

@keyframes workContentAnimateDownToUp {
  0% {
    transform: translateY(80px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes animateWorSpan {
  0% {
    transform: translateY(-80px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
